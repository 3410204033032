<template>
  <div style="position: relative; height: calc(100vh - 90px);">
    <div ref="scrollRef" style="height: calc(100% - 75px); overflow-y: auto;">
      <NCard
        title="试卷大纲"
        :segmented="{ content: 'hard' }"
      >
        <NForm
          ref="formRef"
          label-placement="left"
          :label-width="88"
          require-mark-placement="left"
          :model="formValue"
          :rules="formRules"
        >
          <NFormItem label="标题：" path="title">
            <NInput
              style="width: 300px;"
              maxlength="50"
              show-count
              clearable
              v-model:value="formValue.title"
            />
          </NFormItem>
          <NFormItem label="标签：" path="label_ids">
            <ItemAddLabel
              ref="itemAddLabelRef"
              :label-type="resourceEnum.PAPER_RESOURCE_TYPE"
              @list-update="handleLabelChange"
            />
          </NFormItem>
        </NForm>
        <div style="margin-top: 15px; border-top: 1px dashed #ddd;">
          <ExerciseItem
            v-for="(item, index) in selectedExerciseDatas"
            :key="item.id"
            :item-index="index"
            :exercise-data="item"
            @add-click="handleAfterAdd"
            @exercise-del="handleExerciseDel"
            @exercise-pre="handleSeqPre"
            @exercise-next="handleSeqNext"
            @exercise-top="handleSeqTop"
            @exercise-bottom="handleSeqBottom"
          />
        </div>
        <div style="margin-top: 15px; border-top: 1px dashed #ddd;">
          
        </div>
        <div style="margin-top: 20px; text-align: center;">
          <NButton size="large" type="primary" style="width: 240px;" @click="handleAdd">
            <template #icon>
              <NIcon>
                <AddOutline />
              </NIcon>
            </template>
            添加题目
          </NButton>
          <NButton size="large" type="primary" style="margin-left:20px;width: 240px;" @click="randomAdd">
            <template #icon>
              <NIcon>
                <AddOutline />
              </NIcon>
            </template>
            随机组卷
          </NButton>
        </div>
      </NCard>
    </div>

    <NCard size="small" style="position: absolute; bottom: 0; width: 100%;">
      <NSpace>
        <NButton size="large" @click="handleBack">返回</NButton>
        <NButton size="large" type="primary" style="width: 120px;" @click="handleDone">完成</NButton>
      </NSpace>
      <div style="position: absolute; left: 50%; top: 12px; transform: translate(-50%,0); line-height: 40px; font-size: 16px;">
        当前卷面总分值：
        <span :style="{ color: themeSettings.appThemeColor }">{{ totalScore }} 分</span>
      </div>
    </NCard>
    
    <AddExercisesModal
      :is-test-paper-call="isTestPaperCall"
      ref="addExercisesModalRef"
      :exercise-homework="isHomework"
      @exercise-id-add="handleAddExerciseIds"
    />
    <!-- allArrIDS:所有选择的ID exerciseIdAll:此次选择的ID -->
    <RandomAdd ref="randomVolume"
    @exercise-id-add="handleAddExerciseIds"
    :exercise-id-all="allArrIDS"
    @exercise-id-all="exerciseIdAll"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import { AddOutline } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';
  import AddExercisesModal from '@/components/AddExercisesModal/AddExercisesModal.vue';
  import RandomAdd from '@/components/RandomAdd/index.vue';
  import ExerciseItem from '@/components/ExerciseItem/ExerciseItem.vue';
  
  // import { getLabelList } from '@/api/label.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import { composePaperWayEnum } from '@/enumerators/compose-paper-way-map.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import themeSettings from '@/settings/theme-settings.js'; 


  import { getExercises, createPaperFromExercises, getPaperDetail, editPaperFromExercises } from '@/api/paper.js';

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const route = useRoute();
  const message = useMessage();
  const loading = ref(false);
  const isHomework = ref(1)  // isHomework 0:[应试题目,实训作业] 1:[应试题目] 2:[实训作业]
  const paperId = route.query.id;
  const isEdit = !!paperId;
  const resReqDataMap = {
    title: {
      reqKey: 'Paper[title]',
      default: '',
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    },
    type: {
      reqKey: 'Paper[type]',
      default: composePaperWayEnum.FROM_EXERCISES
    },
    label_ids: {
      reqKey: 'Paper[label]',
      default: [],
      rule: {
        key: 'label_ids',
        type: 'array',
        required: true,
        message: '必选'
      }
    },
    paper_exercises: {
      reqKey: 'Paper[exercises_ids]',
      default: []
    }
  };
  if (isEdit) {
    Object.assign(resReqDataMap, {
      id: {
        reqKey: 'Paper[id]',
        default: paperId
      }
    });
  }
  
  // 初始化时重置随机已选ID集
  sessionStorage.removeItem('SelectedID')

  const formRef = ref(null);
  const formValue = (() => {
    const tempValue = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempValue[key] = resReqDataMap[key].default;
    });
    return reactive(tempValue);
  })();
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqDataMap).forEach(key => {
      const rule = resReqDataMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();
  const triggerLabelValidator = () => {
    formRef.value.validate(
      errors => {},
      rule => rule.key === 'label_ids'
    );
  };

  const itemAddLabelRef = ref(null);
  const handleLabelChange = selectedLabelsArr => {
    formValue.label_ids = selectedLabelsArr.map(item => item.id);
    triggerLabelValidator();
  };

  const isTestPaperCall = ref(true)   // 是否试卷调用
  const addExercisesModalRef = ref(null);
  const selectedExerciseDatas = ref([]);
  let afterIndex;
  const handleAdd = () => {
    afterIndex = selectedExerciseDatas.value.length;
    addExercisesModalRef.value.openModal({
      initIds: formValue.paper_exercises
    });
  };

  const randomVolume = ref(null)
  // 随机组卷
  const randomAdd = () => {
    afterIndex = addExercisesModalRef.value.length;
    randomVolume.value.openModalRandom({
      initIds: formValue.paper_exercises
    });
  };
  const handleAfterAdd = index => {
    afterIndex = index;
    addExercisesModalRef.value.openModal({
      filterIds: formValue.paper_exercises
    });
  };

  const allArrIDS = ref([])
  const exerciseIdAll = ids =>{
    ids.forEach((item,index)=>{
      if(allArrIDS.value.indexOf(item)===-1){
        allArrIDS.value.push(item)
      }
    })
  }
  
  function InitallArrIDS(is,data){
    if(is){
      data.forEach(item2=>{
        if(allArrIDS.value.indexOf(item2)===-1){
          allArrIDS.value.push(item2)
        }
      })
    }else{
      data.forEach(item2=>{
        if(allArrIDS.value.indexOf(item2.id)===-1){
          allArrIDS.value.push(item2.id)
        }
      })
    }
  }
  
    // 数组删除下标
  Array.prototype.remove = function (dx, arr) {
    if (isNaN(dx) || dx > arr.length) {
      return false;
    }
    for (var i = 0, n = 0; i < arr.length; i++) {
      if (arr[i] != arr[dx]) {
        arr[n++] = arr[i];
      }
    }
    arr.length -= 1;
  };

  function removeAllArrIDS(idd){
    allArrIDS.value.forEach((item,index)=>{
      if(item == idd){
        allArrIDS.value.remove(index,allArrIDS.value)
      }
    })
  }

  const handleAddExerciseIds = ids => {
    let idss = ids.ids||ids
    const currentIds = formValue.paper_exercises;
    
    if (afterIndex === currentIds.length) {
      const delIds = currentIds.filter(id => !idss.includes(id));
      delIds.forEach(id => {
        const index = currentIds.indexOf(id);
        selectedExerciseDatas.value.splice(index, 1);
      });
    }
    
    const addIds = idss.filter(id => !currentIds.includes(id));
    InitallArrIDS(true,addIds)// 所选ID push到allArrIDS
    if (addIds.length > 0) {
      // 随机组卷
      loading.value = true;
      getExercises({
        'Paper[exercises_ids]': addIds.join(',')
      }).then(res => {
        if (res.code === SUCCESS) {
          if (Array.isArray(res.data)) {
            selectedExerciseDatas.value.splice(afterIndex + 1, 0, ...res.data);
            updateReqExerciseIds();
          }
        } else {
          updateReqExerciseIds();
        }
      }).catch(err => {
        updateReqExerciseIds();
      }).finally(() => {
        loading.value = false;
      });
    } else {
      updateReqExerciseIds();
    }
  };
  const updateReqExerciseIds = () => {
    formValue.paper_exercises = 
      selectedExerciseDatas.value.map(item => item.id);
  };
  const handleExerciseDel = index => {
    let idd = selectedExerciseDatas.value[index].id
    removeAllArrIDS(idd)
    selectedExerciseDatas.value.splice(index, 1);
    updateReqExerciseIds();
  };
  const handleSeqPre = index => {
    if (index > 0) {
      const item = selectedExerciseDatas.value.splice(index, 1)[0];
      selectedExerciseDatas.value.splice(index - 1, 0, item);
      updateReqExerciseIds();
    }
  };
  const handleSeqNext = index => {
    if (index < selectedExerciseDatas.value.length - 1) {
      const item = selectedExerciseDatas.value.splice(index, 1)[0];
      selectedExerciseDatas.value.splice(index + 1, 0, item);
      updateReqExerciseIds();
    }
  };
  const handleSeqTop = index => {
    if (index > 0) {
      const item = selectedExerciseDatas.value.splice(index, 1)[0];
      selectedExerciseDatas.value.unshift(item);
      updateReqExerciseIds();
    }
  };
  const handleSeqBottom = index => {
    if (index < selectedExerciseDatas.value.length - 1) {
      const item = selectedExerciseDatas.value.splice(index, 1)[0];
      selectedExerciseDatas.value.push(item);
      updateReqExerciseIds();
    }
  };

  const handleBack = () => {
    router.push('list');
  };
  const scrollRef = ref(null);
  const handleDone = () => {
    console.log('确定点击:');
    
    formRef.value.validate(errors => {
      if (!errors) {
        if (formValue.paper_exercises.length > 0) {
          const reqData = {};
          Object.keys(resReqDataMap).forEach(key => {
            reqData[resReqDataMap[key].reqKey] = formValue[key];
          });
          console.log('resReqDataMap:',resReqDataMap);
          console.log('formValue:',formValue);
          let reqFn;
          let successMsg = '';
          if (isEdit) {
            reqFn = editPaperFromExercises;
            successMsg = '试卷编辑成功';
          } else {
            reqFn = createPaperFromExercises;
            successMsg = '试卷添加成功';
          }

          loading.value = true;
          reqFn(reqData).then(res => {
            if (res.code === SUCCESS) {
              message.success(successMsg);
              router.push('list');
            }
          }).catch(err => {}).finally(() => {
            loading.value = false;
          });
        } else {
          message.error('请添加习题');
        }
      } else {
        scrollRef.value.scrollTop = 0;
      }
    });
  };

  const totalScore = computed(() => {
    let total = 0;
    selectedExerciseDatas.value.forEach(item => {
      total += parseFloat(item.score) || 0;
    });
    return (Math.round(total * 100) / 100).toFixed(1);
  });

  if (isEdit) {
    loading.value = true;
    getPaperDetail({
      'Paper[id]': paperId
    }).then(res => {
      if (res.code === SUCCESS) {
        const resData = res.data;
        InitallArrIDS(false,resData.paper_exercises)// 初始化时 所选ID push到allArrIDS
        Object.keys(resReqDataMap).forEach(key => {
          switch (key) {
            case 'id':
            case 'type':
              break;
            case 'label_ids':
              const labels = resData.label_list.map(item => ({
                id: item.label_id,
                title: item.title,
                file_num: item.file_num
              }));
              formValue.label_ids = labels.map(item => item.id);
              itemAddLabelRef.value.setSelectedLabels(labels);
              break;
            case 'paper_exercises':
              selectedExerciseDatas.value = resData.paper_exercises;
              updateReqExerciseIds();
              break;
            default:
              formValue[key] = resData[key];
          }
        });
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }
</script>